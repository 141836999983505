import {faCartShoppingFast, faChevronRight, faFolderPlus} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const items = [
    {
        name: 'Join board from Marketplace',
        description: 'You can find a suitable board from the marketplace.',
        href: '/dashboard/marketplace',
        icon: faCartShoppingFast,
    },
    {
        name: 'Create Board',
        description: 'You can create a new board from scratch.',
        href: '#',
        icon: faFolderPlus,
    },
]

export const BoardListEmpty = () => {
    return (
        <>
            <div className="lg:hidden px-6 py-4">
                <h2 className="text-base font-semibold text-gray-900">No boards found</h2>
                <p className="mt-1 text-sm text-gray-500">Get started by joining via marketplace or create your own.</p>
                <ul role="list" className="mt-6 divide-y divide-gray-200 border-b border-t border-gray-200">
                    {items.map((item, itemIdx) => (
                        <li key={itemIdx}>
                            <div className="group relative flex items-start space-x-3 py-4">
                                <div className="shrink-0">
                <span
                    className={'inline-flex size-10 items-center justify-center rounded-lg bg-metcon'}
                >
                  <FontAwesomeIcon icon={item.icon} aria-hidden="true" className="size-6 text-black"/>
                </span>
                                </div>
                                <div className="min-w-0 flex-1">
                                    <div className="text-sm font-medium text-gray-900">
                                        <a href={item.href}>
                                            <span aria-hidden="true" className="absolute inset-0"/>
                                            {item.name}
                                        </a>
                                    </div>
                                    <p className="text-sm text-gray-500">{item.description}</p>
                                </div>
                                <div className="shrink-0 self-center">
                                    <FontAwesomeIcon icon={faChevronRight} aria-hidden="true"
                                                     className="size-5 text-gray-400 group-hover:text-gray-500"/>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="fixed inset-y-4 left-72 hidden w-3/4 ml-8 py-6 xl:block">
                <h2 className="text-base font-semibold text-gray-900">No boards found</h2>
                <p className="mt-1 text-sm text-gray-500">Get started by joining via marketplace or create your own.</p>
                <ul role="list" className="mt-6 divide-y divide-gray-200 border-b border-t border-gray-200">
                    {items.map((item, itemIdx) => (
                        <li key={itemIdx}>
                            <div className="group relative flex items-start space-x-3 py-4">
                                <div className="shrink-0">
                <span
                    className={'inline-flex size-10 items-center justify-center rounded-lg bg-metcon'}
                >
                  <FontAwesomeIcon icon={item.icon} aria-hidden="true" className="size-6 text-black"/>
                </span>
                                </div>
                                <div className="min-w-0 flex-1">
                                    <div className="text-sm font-medium text-gray-900">
                                        <a href={item.href}>
                                            <span aria-hidden="true" className="absolute inset-0"/>
                                            {item.name}
                                        </a>
                                    </div>
                                    <p className="text-sm text-gray-500">{item.description}</p>
                                </div>
                                <div className="shrink-0 self-center">
                                    <FontAwesomeIcon icon={faChevronRight} aria-hidden="true"
                                                     className="size-5 text-gray-400 group-hover:text-gray-500"/>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    )
};