import React from "react";

export interface DateInputProps {
    name: string;
    id: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value: Date;
    error?: any;
}

export const DateInput = ({name, id, onChange, value, error}: DateInputProps) => {
    return (
        <div>
            <label htmlFor={id} className="block text-sm/6 font-medium text-gray-900">
                {name}
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
                <input
                    id={id}
                    name={id}
                    type="date"
                    placeholder="0.00"
                    onChange={onChange}
                    value={value.toString()}
                    aria-describedby="price-currency"
                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${error ? "ring-red-500" : "ring-gray-300"}`}
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                </div>
            </div>
            {error ? <p id="email-error" className="mt-2 text-sm text-red-600">
                {error}
            </p> : ""}
        </div>
    );
}