import {Link, useMatch} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBarsStaggered, faCartShoppingFast, faChartTreeMap, faWavePulse} from "@fortawesome/pro-regular-svg-icons";
import {classNames} from "../../../providers/utils";

const NavigationList = () => {
    const isStart = useMatch("/dashboard");
    const isBoard = useMatch("/dashboard/boards*");
    const isActivity = useMatch("/dashboard/activities*");
    const isMarketplace = useMatch("/dashboard/marketplace*");

    const navigation = [
        {name: 'Start', href: '/dashboard', icon: faChartTreeMap, current: isStart},
        {name: 'Boards', href: '/dashboard/boards', icon: faBarsStaggered, current: isBoard},
        {name: 'Marketplace', href: '/dashboard/marketplace', icon: faCartShoppingFast, current: isMarketplace},
        {name: 'Activities', href: '/dashboard/activities', icon: faWavePulse, current: isActivity, class: "hidden lg:block"},
    ]

    return (
        <li>
            <div role="list" className="-mx-2 space-y-1">
                {navigation.map((item) => (
                    <li key={item.name} className={item.class}>
                        <Link
                            to={item.href}
                            className={classNames(
                                item.current
                                    ? 'bg-gray-50 text-metcon'
                                    : 'text-gray-700 text-metcon-hover hover:bg-gray-50',
                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                            )}
                        >
                            <FontAwesomeIcon icon={item.icon}
                                             className={classNames(
                                                 item.current ? 'text-metcon' : 'text-gray-400 text.metcon-hover',
                                                 'h-6 w-6 shrink-0'
                                             )}
                                             aria-hidden="true"
                            />
                            {item.name}
                        </Link>
                    </li>
                ))}
            </div>
        </li>
    );
}

export default NavigationList;