import React from "react";
import EmptyBoard from "./EmptyBoard";
import {useDays} from "../../../providers/board/BoardApi";
import Divider from "../../common/Divider";
import dayjs from "dayjs";

interface BoardProps {
    id: string;
}

const Board: React.FC<BoardProps> = ({id}) => {
    const {data} = useDays(id);

    if (!data || !data.pages) {
        return <EmptyBoard/>;
    }

    return (
        <>
            <div className="flex flex-col items-center justify-center gap-16 px-5 py-5 lg:px-16 lg:py-16 w-full max-w-3xl ">
                {data.pages.flatMap(page => page).map(day => (
                    <div
                        className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow-md hover:shadow-lg w-full">
                        {day.image && <div className="px-4 py-5 h-80 bg-center bg-no-repeat bg-cover"
                                           style={{backgroundImage: `url(${day.image})`}}/>}
                        <div className="px-4 py-5 bg-gray-50 text-center">
                            <h2 className="text-xl">{dayjs(day.day).format('D MMMM, YYYY')}</h2>
                            {/*<Divider text={"Next session"}/>*/}
                        </div>
                        <div className="px-4 py-5 sm:p-6 bg-gray-50">
                            {day.sessions.map((session: { workouts: string[] }, index: number) => (
                                <>
                                    {index !== 0 && <Divider bgColor={"bg-gray-50 py-4"} text="Next session"/>}
                                    {session.workouts.map((workout: string) => (
                                        <div className="py-2">
                                            <p className="text-sm font-light" style={{
                                                whiteSpace: 'pre'
                                            }}>{workout}</p>
                                        </div>
                                    ))}

                                </>
                            ))}
                        </div>
                        <div className="px-4 py-2 sm:px-6 bg-gray-50">
                            <p className="text-xs font-light text-gray-500">Scores: {day.scoreCount || 0}</p>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}

export default Board;