import React from "react";

export interface InputProps {
    name: string;
    type: string;
    id: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value: string;
    autocomplete?: string;
    required?: boolean;
    error?: string;
}

export const Input = ({name, type, id, onChange, value, autocomplete, required = false, error}: InputProps) => {
    return (
        <div>
            <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
                {name}
            </label>
            <div className="mt-2">
                <input
                    id={id}
                    name={id}
                    type={type}
                    onChange={onChange}
                    value={value}
                    autoComplete={autocomplete}
                    required={required}
                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${error ? "ring-red-500" : "ring-gray-300"}`}
                />
            </div>
            {error ? <p id="email-error" className="mt-2 text-sm text-red-600">
                {error}
            </p> : ""}
        </div>
    );
}