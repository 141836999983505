import React from "react";
import {useFormik} from "formik";
import {AlertType, useAlert} from "../providers/alertProvider";
import {useResetPassword} from "../providers/auth/AuthApi";
import {InitResetPasswordCommand} from "../providers/auth/AuthApiTypes";
import {useNavigate} from "react-router-dom";
import * as yup from "yup";
import {Logomark} from "../components/common/Logo";
import {Input} from "../components/common/inputs/Input";
import {ValidationErrorsEnum} from "../providers/validationErrors";

const Forgot = () => {
    const navigate = useNavigate();
    const {showAlert} = useAlert();
    const reset = useResetPassword();

    const validationSchema = yup.object({
        email: yup
            .string()
            .email(ValidationErrorsEnum.ENTER_MAIL)
            .required(ValidationErrorsEnum.ENTER_MAIL),
    });

    const initialValues: InitResetPasswordCommand = {
        email: "",
    };

    const onSubmit = (values: InitResetPasswordCommand) => {
        reset.mutateAsync(values)
            .then(() => {
                navigate('/')
                showAlert("Password reset", "Check your inbox", AlertType.SUCCESS)
            })
            .catch((err: any) => {
                showAlert("Problem with reset", err?.response?.data?.message, AlertType.DANGER);
            })
    };

    const formik = useFormik({
        initialValues, onSubmit,
        validationSchema: validationSchema,
        validateOnBlur: false,
        validateOnChange: false,
    })

    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto flex content-center flex-col sm:w-full sm:max-w-sm">
                    <Logomark className="h-20 w-20 m-auto" />
                    <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Reset your password
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <div className="space-y-6">
                        <Input name={'Email Address'} type={'email'} id={'email'} onChange={formik.handleChange}
                               value={formik.values.email} error={formik.errors.email}/>

                        <div>
                            <button
                                onClick={() => formik.handleSubmit()}
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-metcon px-3 py-1.5 text-sm font-semibold leading-6 text-gray-800 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Reset password
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Forgot;