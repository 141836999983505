import {BoardDto} from "../../../providers/board/BoardApiTypes";
import {Menu} from "@headlessui/react";
import {classNames} from "../../../providers/utils";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/pro-regular-svg-icons";
import dayjs from "dayjs";
import {useJoinBoard} from "../../../providers/board/BoardApi";

type SingleBoardProps = {
    board: BoardDto;
}

const MarketplaceItem = ({board}: SingleBoardProps) => {
    const placeholder = require("../../../images/180.png")
    const date = dayjs(board.updatedAt).format('LLL')
    const joinMutation = useJoinBoard()

    return (
        <li key={board._id} className="overflow-hidden rounded-xl border border-gray-200">
            <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                <img
                    alt={board.name}
                    src={board.image || placeholder}
                    className="size-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                />
                <div className="text-sm/6 font-medium text-gray-900">{board.name}</div>
                <Menu as="div" className="relative ml-auto">
                    <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                        <span className="sr-only">Open options</span>
                        <FontAwesomeIcon icon={faBars} size={'lg'}/>
                    </Menu.Button>
                    <Menu.Items
                        className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                    >
                        <Menu.Item>
                            <a onClick={() => joinMutation.mutate(board._id)}
                                href="#"
                                className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                            >
                                Join<span className="sr-only">, {board.name}</span>
                            </a>
                        </Menu.Item>
                        {board.website ? <Menu.Item>
                            <a
                                href={"https://" + board.website}
                                className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                            >
                                Visit Website<span className="sr-only">, {board.name}</span>
                            </a>
                        </Menu.Item> : null }
                    </Menu.Items>
                </Menu>
            </div>
            <div className={'mx-6 my-2'}>
                <span className={'text-xs text-gray-500 font-light'}>{board.description}</span>
            </div>
            <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm/6">
                <div className="flex justify-between gap-x-4 py-3">
                    <dt className="text-gray-500">Last update</dt>
                    <dd className="text-gray-700">
                        <time dateTime={board.updatedAt.toString()}>{date}</time>
                    </dd>
                </div>
                <div className="flex justify-between gap-x-4 py-3">
                    <dt className="text-gray-500">Members</dt>
                    <dd className="flex items-start gap-x-2">
                        <div className="font-medium text-gray-900">{board.popularity}</div>
                        <div
                            className={classNames(
                                'text-green-700 bg-green-50 ring-green-600/20',
                                'rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset',
                            )}
                        >
                            {board.price ? `${board.price}$` : 'Free'}
                        </div>
                    </dd>
                </div>
            </dl>
        </li>
    );
}

export default MarketplaceItem;