import Chart from "react-apexcharts";
import React from "react";
import {useCalendar} from "../../../providers/readingsApi";
import {NoWeeklyData} from "./NoWeeklyData";

const WeeklySummary = () => {
    const {data} = useCalendar();

    if(!data) {
        return null;
    }

    if(data.length === 0) {
        return <NoWeeklyData />
    }

    const options = {
        chart: {
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 150
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350
                }
            },
            id: 'activityChart',
        },
        yaxis: {
            labels: {
                formatter: function (value: number) {
                    return value;
                }
            }
        }
        // xaxis: {
        //     tickAmount: 12,
        //     labels: {
        //         showDuplicates: false,
        //         formatter: function (value: string) {
        //             return dayjs(value).diff(startTime, 'minute');
        //         }
        //     }
        // },
        // tooltip: {
        //     x: {
        //         formatter: function (value: number) {
        //             return dayjs(activity.readings[value - 1].time).format("HH:mm")
        //         }
        //     },
        //     y: {
        //         formatter: function (value: number) {
        //             return value + "bpm";
        //         }
        //     }
        // }
    };
    const series = [{
        name: `Effort`,
        data: (data).map(el => ({
            x: el.day,
            y: (el.effort).toFixed(1)
        })) || [],
    }, {
        name: `Recovery`,
        data: (data).map(el => ({
            x: el.day,
            y: el.recovery.toFixed(0)
        })) || [],
    }]

    return (
        <div className="py-8">
            {/*@ts-ignore*/}
            <Chart options={options} series={series} type="line" width={'100%'} height={400}/>
        </div>
    )
}

export default WeeklySummary;