import {Link, useMatch} from "react-router-dom";
import {useGroups} from "../../../providers/readingsApi";
import {GroupDto} from "../../../providers/readingsTypes";
const emptyGroupImage = require('../../../images/empty.png');

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

const GroupListItem = ({group}: {group: GroupDto}) => {
    const isActive = useMatch(`/dashboard/group/${group._id}`);

    return (
        <li key={group.name}>
            <Link
                to={`/dashboard/group/${group._id}`}
                className={classNames(
                    isActive
                        ? 'bg-gray-50 text-metcon'
                        : 'text-gray-700',
                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                )}
            >
                <img className={'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg'}
                     src={group.image || emptyGroupImage} alt={group.image}/>
                <span className="truncate">{group.name}</span>
            </Link>
        </li>
    )
}

const GroupList = () => {
    const {data} = useGroups();

    if(data?.length === 0) {
        return null;
    }

    return (
        <li>
            <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
            <ul role="list" className="-mx-2 mt-2 space-y-1">
                {(data || []).map((team) => (
                    <GroupListItem group={team} />
                ))}
            </ul>
        </li>
    );
}

export default GroupList;