import BoardList from "../../components/dashboard/board/BoardList";
import {useParams} from "react-router-dom";
import SelectBoard from "../../components/dashboard/board/SelectBoard";
import Board from "../../components/dashboard/board/Board";
import {useBoards} from "../../providers/board/BoardApi";
import {BoardListEmpty} from "../../components/dashboard/board/BoardListEmpty";
import {BoardDto} from "../../providers/board/BoardApiTypes";

const Boards = () => {
    const {id} = useParams();
    const {data} = useBoards({});

    if(!data) {
        return null;
    };

    if(data.length === 0) {
        return <BoardListEmpty />;
    }

    return (
        <>
            <BoardList data={data as BoardDto[]}/>
            <main className="lg:pl-72">
                <div className="xl:pl-96">
                    <div className="flex items-center justify-center min-h-screen">
                        {id ? <Board id={id}/> : <SelectBoard/>}
                    </div>
                </div>
            </main>
        </>
    );
}

export default Boards;