import {
    ActivateCommand,
    InitResetPasswordCommand,
    LoginCommand,
    LoginResponse,
    RegisterCommand,
    ResetPasswordCommand
} from "./AuthApiTypes";
import {useMutation} from "react-query";
import {authClient} from "../apiClients";

export const useLogin = () =>
    useMutation<LoginResponse, unknown, LoginCommand>('login', (command: LoginCommand) =>
            authClient.post(`login`, command)
                .then((data) => data.data),
    );

export const useReset = () =>
    useMutation<unknown, unknown, ResetPasswordCommand>('reset-password', (command: ResetPasswordCommand) =>
        authClient.post(`reset-password`, command)
            .then((data) => data.data),
    );

export const useActivate = () =>
    useMutation<unknown, unknown, ActivateCommand>('activate', (command: ActivateCommand) =>
        authClient.post(`activate`, command)
            .then((data) => data.data),
    );

export const useRegister = () =>
    useMutation('register', (command: RegisterCommand) =>
        authClient.post(`register`, command)
            .then((data) => data.data),
    );

export const useResetPassword = () =>
    useMutation('init-reset-password', (command: InitResetPasswordCommand) =>
        authClient.post(`init-reset-password`, command)
            .then((data) => data.data),
    );