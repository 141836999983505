import {useMarketplace} from "../../providers/board/BoardApi";
import {MarketplaceListEmpty} from "../../components/dashboard/marketplace/MarketplaceListEmpty";
import React from "react";
import MarketplaceItem from "../../components/dashboard/marketplace/MarketplaceItem";

const Marketplace = () => {
    const {data} = useMarketplace({});

    if (!data) {
        return null;
    }

    if (data.length === 0) {
        return <MarketplaceListEmpty/>;
    }

    return (
        <div className={'ml-72 px-4 py-4'}>
            <ul role="list" className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                {data.map((board) => (
                    <MarketplaceItem board={board} key={board._id}/>
                ))}
            </ul>
        </div>
    );
}

export default Marketplace;