import { boardClient } from "../apiClients";
import {useInfiniteQuery, useMutation, useQuery} from "react-query";
import {BoardDto, BoardFiltersParams, MarketplaceFiltersParams} from "./BoardApiTypes";
import {queryClient} from "../queryClient";

export const useBoards = (filters: BoardFiltersParams) =>
  useQuery<BoardDto[]>(
    `boards`,
    async () => boardClient.get(`/boards/all/access`, { params: filters }).then(data => data.data),
  );

export const useDays = (boardId: string) =>
  useInfiniteQuery({
    queryKey: `days-${boardId}`,
    queryFn: async ({ pageParam = 0 }) =>
      boardClient
        .get(`days/${boardId}?page=${pageParam}&size=21`)
        .then((res) => res.data),
    getNextPageParam: (lastPage, allPages) => allPages.length
  });

export const useMarketplace = (filters: MarketplaceFiltersParams) =>
    useQuery<BoardDto[]>(
        `marketplace`,
        async () => boardClient.get(`boards/all/marketplace`, {params: filters})
            .then(data => data.data),
    );

export const useJoinBoard = () =>
    useMutation('joinBoard', (id: string) =>
            boardClient.put(`subscriptions/join/${id}`)
                .then((data) => data.data),
        {
            onSuccess: async () => {
                await queryClient.fetchQuery(`boards`).catch(err => console.log('boards'))
                await queryClient.fetchQuery(`marketplace`).catch(err => console.log('marketplace'))
            },
        }
    );

export const useLeaveBoard = () =>
    useMutation('joinBoard', (id: string) =>
            boardClient.put(`subscriptions/leave/${id}`)
                .then((data) => data.data),
        {
            onSuccess: async () => {
                await queryClient.fetchQuery(`boards`)
                await queryClient.fetchQuery(`marketplace`)
            },
        }
    );