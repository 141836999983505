import {BoardDto} from "../../../providers/board/BoardApiTypes";
import {Link, useMatch} from "react-router-dom";

type SingleBoardProps = {
    board: BoardDto;
}

const BoardMenuItem = ({board}: SingleBoardProps) => {
    const isActive = useMatch(`/dashboard/boards/${board._id}`);

    return (
        <Link to={`/dashboard/boards/${board._id}`} key={board._id}>
            <li key={board._id} className={`flex gap-x-4 py-4`}>
                <img style={{objectFit: 'cover'}}
                     className="h-12 w-12 flex-none rounded-full bg-gray-50" src={board.image}
                     alt={board.name}/>
                <div className="min-w-0">
                    <p className={`text-sm font-semibold leading-6 ${isActive ? "text-metcon" : "text-gray-900"}`}>{board.name}</p>
                    <p className={`truncate text-xs leading-5 text-gray-500  ${isActive && "font-medium"}`}>{board.name}</p>
                </div>
            </li>
        </Link>
    );
}

export default BoardMenuItem;