import React from "react";
import {useFormik} from "formik";
import {AlertType, useAlert} from "../providers/alertProvider";
import {useRegister} from "../providers/auth/AuthApi";
import {RegisterCommand} from "../providers/auth/AuthApiTypes";
import {useNavigate} from "react-router-dom";
import * as yup from "yup";
import {Logomark} from "../components/common/Logo";
import {ValidationErrorsEnum} from "../providers/validationErrors";
import {Input} from "../components/common/inputs/Input";
import {NumberInput} from "../components/common/inputs/NumberInput";
import {DateInput} from "../components/common/inputs/DateInput";

const Register = () => {
    const {showAlert} = useAlert();
    const navigate = useNavigate();
    const registerMutation = useRegister();

    const validationSchema = yup.object({
        email: yup
            .string()
            .email(ValidationErrorsEnum.ENTER_MAIL)
            .required(ValidationErrorsEnum.ENTER_MAIL),
        password: yup
            .string()
            .required(ValidationErrorsEnum.FIELD_REQUIRED),
        retypedPassword: yup
            .string()
            .oneOf([yup.ref('password')], ValidationErrorsEnum.MATCH),
        firstName: yup
            .string()
            .required(ValidationErrorsEnum.ENTER_NAME),
        lastName: yup
            .string()
            .required(ValidationErrorsEnum.ENTER_NAME),
        dateOfBirth: yup
            .date()
            .max(new Date(Date.now() - 567648000000), "You must be at least 18 years")
            .required(ValidationErrorsEnum.FIELD_REQUIRED),
    });

    const initialValues: RegisterCommand = {
        email: "",
        password: "",
        retypedPassword: "",
        firstName: "",
        lastName: "",
        weight: 50,
        height: 160,
        dateOfBirth: new Date(),
    };

    const onSubmit = (values: RegisterCommand) => {
        registerMutation.mutateAsync(values)
            .then(() => showAlert("User created", "Please check your inbox and activate account.", AlertType.SUCCESS))
            .then(() => navigate('/login'))
            .catch((err) => showAlert("Problem with register!", err?.response?.data?.message, AlertType.DANGER));
    };

    const formik = useFormik({
        initialValues, onSubmit,
        validationSchema: validationSchema,
        validateOnBlur: false,
        validateOnChange: false,
    })

    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto flex content-center flex-col sm:w-full sm:max-w-sm">
                    <Logomark className="h-20 w-20 m-auto"/>
                    <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Create account
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <div className="space-y-4">
                        <Input name={'Email Address'} type={'email'} id={'email'} onChange={formik.handleChange}
                               value={formik.values.email} error={formik.errors.email}/>

                        <Input name={'Password'} type={'password'} id={'password'} onChange={formik.handleChange}
                               value={formik.values.password} error={formik.errors.password}/>

                        <Input name={'Retype Password'} type={'password'} id={'retypedPassword'}
                               onChange={formik.handleChange}
                               value={formik.values.retypedPassword} error={formik.errors.retypedPassword}/>

                        <Input name={'First Name'} type={'text'} id={'firstName'} onChange={formik.handleChange}
                               value={formik.values.firstName} error={formik.errors.firstName}/>

                        <Input name={'Last Name'} type={'text'} id={'lastName'} onChange={formik.handleChange}
                               value={formik.values.lastName} error={formik.errors.lastName}/>

                        <NumberInput id={'weight'} name={'Weight'} value={formik.values.weight}
                                     onChange={formik.handleChange} error={formik.errors.weight} unit={'kg'}/>

                        <NumberInput id={'height'} name={'Height'} value={formik.values.height}
                                     onChange={formik.handleChange} error={formik.errors.height} unit={'cm'}/>

                        <DateInput id={'dateOfBirth'} name={'Date of birth'} value={formik.values.dateOfBirth}
                                     onChange={formik.handleChange} error={formik.errors.dateOfBirth} />

                        <div>
                            <button
                                onClick={() => formik.handleSubmit()}
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-metcon px-3 py-1.5 text-sm font-semibold leading-6 text-gray-800 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Reset password
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Register;