import {useAuth} from "../../../providers/authProvider";
import {useUserStatistics} from "../../../providers/readingsApi";

const Header = () => {
    const {user} = useAuth();
    const {data} = useUserStatistics();
    const placeholder = require('../../../images/placeholder.png');

    const stats = [
        { label: 'Current login streak', value: 12 },
        { label: 'Effort PR', value: data?.maxEffort?.toFixed(1) || 0.0 },
        { label: 'Max heart rate', value: data?.maxHR || 0.0 },
    ]

    return (
        <div className="overflow-hidden rounded-lg bg-white shadow">
            <h2 className="sr-only" id="profile-overview-title">
                Profile Overview
            </h2>
            <div className="bg-white p-6">
                <div className="sm:flex sm:items-center sm:justify-between">
                    <div className="sm:flex sm:space-x-5">
                        <div className="flex-shrink-0">
                            <img className="mx-auto h-20 w-20 rounded-full" src={user?.avatar || placeholder} alt=""/>
                        </div>
                        <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                            <p className="text-sm font-medium text-gray-600">Welcome back,</p>
                            <p className="text-xl font-bold text-gray-900 sm:text-2xl">{user?.firstName} {user?.lastName}</p>
                            <p className="text-sm font-medium text-gray-600">{user?.box}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="grid grid-cols-1 divide-y divide-gray-200 border-t border-gray-200 bg-gray-50 sm:grid-cols-3 sm:divide-x sm:divide-y-0">
                {stats.map((stat) => (
                    <div key={stat.label} className="px-6 py-5 text-center text-sm font-medium">
                        <span className="text-gray-600">{stat.label}</span> - <span
                        className="text-gray-900">{stat.value}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Header;