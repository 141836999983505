import {createBrowserRouter} from "react-router-dom";
import Home from "../views/Home";
import Dashboard from "../views/dashboard/Dashboard";
import Start from "../views/dashboard/Start";
import React from "react";
import Boards from "../views/dashboard/Boards";
import Login from "../views/Login";
import Activities from "../views/dashboard/Activities";
import Group from "../views/dashboard/Group";
import Activate from "../views/Activate";
import Reset from "../views/Reset";
import Register from "../views/Register";
import Forgot from "../views/Forgot";
import Marketplace from "../views/dashboard/Marketplace";

export const router = createBrowserRouter([
    {
        path: "/",
        element: (<Home/>),
    },
    {
        path: "/login",
        element: (<Login/>),
    },
    {
        path: "activate/:token",
        element: (<Activate/>),
    },
    {
        path: "reset/:token",
        element: (<Reset/>),
    },
    {
        path: "register",
        element: (<Register/>),
    },
    {
        path: "forgot",
        element: (<Forgot/>),
    },
    {
        path: "dashboard",
        element: (<Dashboard><Start/></Dashboard>),
    },
    {
        path: "dashboard/boards",
        element: (<Dashboard><Boards/></Dashboard>),
    },
    {
        path: "dashboard/boards/:id",
        element: (<Dashboard><Boards/></Dashboard>),
    },
    {
        path: "dashboard/marketplace",
        element: (<Dashboard><Marketplace/></Dashboard>),
    },
    {
        path: "dashboard/activities",
        element: (<Dashboard><Activities/></Dashboard>),
    },
    {
        path: "dashboard/activities/:id",
        element: (<Dashboard><Activities/></Dashboard>),
    },
    {
        path: "dashboard/group/:id",
        element: (<Dashboard><Group/></Dashboard>),
    },
]);